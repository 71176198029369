<template>
    <div class="fix" v-show="fixshow">
        <div class="fix-div">
            <img class="fix-logo" :src="require('/static/images/guolao2.png')" alt="" @click="fixback">
            <div class="fix-box">
                <div class="fix-trade">
                  <router-link  tag="div" :to="{path:'/trade'}">商标服务</router-link>
                  <div class="fix-div1">
                      <div v-for="(v,k,index) in myarr" class="fix-div1-box">
                          <p @click="moveto(v.goodsName)"> {{v.goodsName}}<span>|</span></p>
                      </div>
                  </div>
              </div>
              <div class="fix-patent">
                  <router-link tag="div" :to="{path:'/patent'}">专利服务</router-link>
                  <div class="fix-div4">
                      <div v-for="(v,k,index) in copyarr" :key="index" class="fix-div2-box">
                          <p @click="moveto1(v.goodsName)">{{v.goodsName}}<span>|</span></p>
                      </div>
                  </div>
              </div>
              <div class="fix-copyright">
                  <router-link tag="div" :to="{path:'/copyright'}">版权服务</router-link>
                  <div class="fix-div3">
                      <div v-for="(v,k,index) in copyright" :key="index" class="fix-div3-box">
                          <p @click="moveto2(v.goodsName)">{{v.goodsName}}<span>|</span></p>
                      </div>
                  </div>
              </div>
              <!-- <div class="fix-property">
                  <div @click="brandBuy">知产交易</div>
              </div> -->
              <!-- <div class="fix-tool">
                  <div>知产工具</div>
                  <div class="fix-div2">
                      <div @click="toregister">商标评估<span>|</span></div>
                      <div @click="creatname">商标取名<span>|</span></div>
                      <div @click="todesign">Logo设计<span>|</span></div>
                      <div @click="assets">知产管理<span>|</span></div>
                      <div @click="assets2">工商核名<span>|</span></div>
                      <div @click="topatentanalyse">专利申请评估</div>
                  </div>
              </div> -->
              <div class="fix-abouts">
                  <div>关于我们</div>
                  <div class="fix-div5">
                      <router-link tag="p" to="/about">公司介绍</router-link><span>|</span>
                      <router-link tag="p" to="/link">联系我们</router-link>
                      <!-- <span>|</span>
                      <router-link tag="p" to="/news">新闻中心</router-link> -->
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "fix",
        components:{

        },
        data(){
          return {
            fixshow:false,
            myarr:[],
            copyarr:[],
            copyright:[],
          }

        },
        mounted(){
            this.getNav();
            this.getList1();
            this.getList2();
            window.addEventListener('scroll', this.windowScroll)
        },
        methods:{
            fixback(){
                 this.$router.push('/')
            },
            assets2(){
            if(this.$cookie.getCookie('u_id')){
              this.$router.push('/verification')
            }else {
              this.$router.push({
                    path:'/login',
                    query:{
                    good:0
                  }
              })
            }
          },
            tolisttype(){
              this.$router.push('/listtype');
            },
            topatentanalyse(){
              if(this.$cookie.getCookie('u_id')){
                this.$router.push('/patentAnalyse');
              }else{
                this.$router.push({
                    path:'/login',
                    query:{
                    good:0
                    }
                })
                }
            },
            windowScroll() {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if(scrollTop>100){
                    this.fixshow = true
                }else{
                    this.fixshow = false
                }
            },
          getNav(){
            this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
              goodsLevel:'1',
              goodsType:'1'
            }).then(res=>{
              if(res.data.code == "200"){
                // console.log(res)
                this.myarr = res.data.data;
              }
            })
        },

        getList1(){
          this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
            goodsLevel:'1',
              goodsType:'2'
          }).then( res => {
            // console.log(res)
            if(res.data.code == "200"){
              this.copyarr = res.data.data
            }
          })
        },
      getList2(){
        this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
          goodsLevel:'1',
              goodsType:'3'
        }).then( res => {
          // console.log(res)
          if(res.data.code == "200"){
            this.copyright = res.data.data
          }
        })
      },
      moveto(val){
              this.$router.push({
              path:'/tradeDetail',
                  query:{
                      parentName:val,
                      secondName:0
                  }
              });
          },
      moveto1(val){
          this.$router.push({
                path:'/patentDetail',
                query:{
                  parentName:val,
                  secondName:0
                }
          })
      },
       //版权跳转
        moveto2(val){
            this.$router.push({
                      path:'/copyrightDetail',
                      query:{
                        parentName:val,
                        secondName:0
                      }
            })

        },
          toregister(){
          if(this.$cookie.getCookie('u_id')){
            this.$router.push('/recome')
          }else{
            this.$router.push({
              path:'/login',
              query:{
                good:0
              }
            })
          }
        },
         creatname(){
              if(this.$cookie.getCookie('u_id')){
                this.$router.push('/picksize')
                }else{
                this.$router.push({
                    path:'/login',
                    query:{
                    good:0
                    }
                })
                }
          },
      todesign(){
        if(this.$cookie.getCookie('u_id')){
          this.$router.push('/designbrand')
        }else{
          this.$router.push({
            path:'/login',
            query:{
              good:0
            }
          })
        }
      },
      assets(){
        if(this.$cookie.getCookie('u_id')){
          this.$router.push('/intellectualProperty')
        }else {
          this.$router.push({
                path:'/login',
                query:{
                good:0
              }
          })
        }
      },
      brandBuy() {
        this.$router.push('/brandBuy')
      }
        }
    }
</script>

<style scoped>
   .fix{
       position: fixed;
       width: 100%;
       height: 75px;
       top:0;
       background-color: #292b39;
       z-index: 100;
   }
   .fix-div{
       width: 1200px;
       /* height: 75px; */
       line-height: 75px;
       margin: 0 auto;
   }
   .fix-logo{
       width: 79px;
       height: 26px;
       margin: 25px 54px 0 0;
       float: left;
   }
   .fix-box{
       float: left;
       width: 710px;
       height: 75px;
   }
   .fix-box>div{
       float: left;
       color: white;
       margin-right: 60px;
   }
   .fix-box>div:last-child{
       margin-right: 0;
   }
   .fix-trade,.fix-copyright,.fix-patent,.fix-property,.fix-abouts,.fix-tool{
        position: relative;
        cursor: pointer;
    }
    .fix-div1,.fix-div2,.fix-div3,.fix-div4{
        position:absolute;
        background-color: rgba(51, 51, 51, 0.8);
        height: 0;
        transition: all 1s ease;
        opacity: 0;
        z-index: 3;
        overflow: hidden;
        padding-left: 20px;
    }
    .fix-div5{
        width: 200px;
        position:absolute;
        background-color: rgba(51, 51, 51, 0.9);
        height: 0;
        transition: all 1s ease;
        opacity: 0;
        z-index: 3;
        overflow: hidden;
        padding-top:13px;
        left: -75px;
        margin-top: -15px;
        text-align: center;
    }
    .fix-abouts:hover .fix-div5{
        height: 30px;
        opacity: 1;
    }
    .fix-abouts:hover{
      color: #EB5E00;
    }
    .fix-div2{
        line-height: normal;
        width: 580px;
        margin-top: -15px;
        left: -200px;
    }
    .fix-div2>div{
        float: left;
        width: 95px;
        height: 20px;
         margin-top: 13px;
         color: white;
         text-align: center;
         font-size: 12px;
    }
    .fix-div2>div>span{
        float: right;
    }
    .fix-tool:hover .fix-div2{
        height: 40px;
        opacity: 1;
    }
    .fix-tool:hover{
      color: #EB5E00;
    }
    .fix-div5>p{
        float: left;
        font-size: 12px;
        line-height: normal;
        color: #ffffff;
        height: 30px;
        margin: 0 22px;
        vertical-align: middle;
    }
    .fix-div5>span{
      float: left;
      font-size: 14px;
      color: #ffffff;
      line-height: normal;
      vertical-align: middle;
    }
    .fix-div1,.fix-div4{
        width: 562px;
        margin-top: -15px;
        left: -60px;
    }
    .fix-div1>div,.fix-div4>div{
        width: 150px;
        float: left;
        height: 16px;
        font-size: 12px;
        color: #ffffff;
        line-height: normal;
        margin-right: 30px;
        margin-top: 15px;
        text-align: center;
        /* border:1px solid red; */
    }
    .fix-div1>div>span,.fix-div4>div>span{
      float: right;
    }
    .fix-div1>div>p>span,.fix-div4>div>p>span{
      float: right;
    }

    .fix-copyright:hover .fix-div3{
        height: 76px;
        opacity: 1;
    }
    .fix-copyright:hover{
      color: #EB5E00;
    }
    .fix-div3>div>p>span{
      float: right;
    }
    .fix-div3{
        margin-top: -15px;
        width:410px;
        left: -140px;
    }
    .fix-div3>div{
      width: 120px;
        float: left;
        height: 16px;
        font-size: 12px;
        color: #ffffff;
        line-height: normal;
        margin-top: 15px;
        text-align: center;
    }
    .fix-trade:hover{
      color: #EB5E00;
    }
    .fix-trade:hover .fix-div1{
        height: 76px;
        opacity: 1;
    }
    .fix-patent:hover{
      color: #EB5E00;
    }
    .fix-patent:hover .fix-div4{
        height:110px;
        opacity: 1;
    }
</style>
